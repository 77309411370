import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { STATE_TOKEN } from '@sod/store/app.state.model';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(private store: Store, private routing: Router) { }

  ngOnInit() {
    // const state = this.store.selectSnapshot(STATE_TOKEN);
    // if (!state.token) this.routing.navigate(["login"]);

  }

}
